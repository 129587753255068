import React, { useEffect, useState } from 'react';
import PlatesView from './PlatesView';
import Spinner from '../../../../shared/Spinner';
import ticketServices from '../../../../services/ticket-service';
import parkingService from '../../../../services/parking-service';
import scanPlateServices from '../../../../services/scan-plate-service';


export default function Plates(props) {
    const [plates, setPlates] = useState([]);
    const [fiteredPlates, setFiteredPlates] = useState([]);
    const [parkings, setParkings] = useState([]);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        getPlates();
    }, [])

    const getPlates = async()=>{
        setSpinner(true);
        const res = await parkingService.getParkingsByCity({city_id: props.inputField.city});
        console.log(res.data)
        setParkings(res.data);
        let allPlates = [...res.data];
        let residantPlates = res.data.filter(x => x.plate_two || x.plate_three);
        residantPlates.forEach(x => {
            if(x.plate_two)
                allPlates.push({
                    ...x,
                    plate: x.plate_two
                })
            if(x.plate_three)
                allPlates.push({
                    ...x,
                    plate: x.plate_three
                })
        })
        setPlates(allPlates);
        setFiteredPlates(allPlates);
        setSpinner(false);
    }

    const handleSearch = async () => {
        // setSpinner(true);
        const user = JSON.parse(sessionStorage.getItem('userLogged'));
        const getScanPlates = await scanPlateServices.getScanPlatesByToken(user.token);
        const isPlateAlreadyScanned = getScanPlates.data.find(x => x.plate == props.inputField.plate.toUpperCase());
        console.log(isPlateAlreadyScanned);
        if(!isPlateAlreadyScanned){
            await scanPlateServices.addScanPlate({
                session_token: user.token,
                plate: props.inputField.plate.toUpperCase(),
                org: user.result.org._id,
                user: user.result._id
            });
        }
        setSpinner(false);
        let findPlateOne = parkings.find(x => x.plate == props.inputField.plate.toUpperCase())
        let findPlateTwo = parkings.find(x => x.plate_two == props.inputField.plate.toUpperCase())
        let findPlateThree = parkings.find(x => x.plate_three == props.inputField.plate.toUpperCase())
        
        if(findPlateOne && (getScanPlates.data.find(x => x.plate == findPlateOne.plate_two) || getScanPlates.data.find(x => x.plate == findPlateOne.plate_three))){
            let plate = plates;
            plate[plates.findIndex(x => x.plate == props.inputField.plate.toUpperCase())].status = 'shared';
            setPlates(plate)
        }
        if(findPlateTwo && (getScanPlates.data.find(x => x.plate == findPlateTwo.plate) || getScanPlates.data.find(x => x.plate == findPlateTwo.plate_three))){
            let plate = plates;
            plate[plates.findIndex(x => x.plate == props.inputField.plate.toUpperCase())].status = 'shared';
            setPlates(plate)
        }
        if(findPlateThree && (getScanPlates.data.find(x => x.plate == findPlateThree.plate_two) || getScanPlates.data.find(x => x.plate == findPlateThree.plate))){
            let plate = plates;
            plate[plates.findIndex(x => x.plate == props.inputField.plate.toUpperCase())].status = 'shared';
            setPlates(plate)
        }
        setPlates(plates);
        let filteredRows = plates.filter((row) => {
            return row.plate.toLowerCase() == props.inputField.plate?.toLowerCase();
        });
        if(!filteredRows.length){
            filteredRows = [{
                plate: props.inputField.plate.toUpperCase()
            }]
            props.setInputField({...props.inputField, plate: props.inputField.plate.toUpperCase()});
        }
        setFiteredPlates(filteredRows);
    }

    const clearSearch = () => {
        let inputField = props.inputField;
        inputField.plate = '';
        props.setInputField(inputField);
        setFiteredPlates(plates);
    }

    return (
        <>
            <PlatesView
                org={props.org}
                literals={props.literals}
                plates={fiteredPlates}
                inputField={props.inputField}

                handleSearch={() => handleSearch()}
                handlePlateSelect={(e)=> props.setInputField({...props.inputField, ...e})}
                clearSearch={() => clearSearch()}
            />
            <Spinner
                spinner = {spinner}
            />
        </>
    );
}